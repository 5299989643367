.table {
    font-size: 1em;
}
.style2-table thead {
    background: none !important;
}
.style2-table thead tr th:first-child {
    border-top-left-radius: 10px;
    
}
.style2-table thead tr th:first-child {
    border-top-left-radius: 10px;
    /* border-radius: 10px !important; */
}

.table thead tr th {
    padding-top: 12px !important;
    padding-bottom: 10px !important;
}
.style2-table thead tr th {
    background-color: #f5f5f5 !important;
}
.table thead th {
    text-transform: uppercase;
    font-size: 0.7rem;
}

/* .sidebar{
    background-color: #01054e !important;
}

.sidebar-nav .nav-link.active {
    background: rgb(255 255 255 / 31%) !important;
} */
