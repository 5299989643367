@font-face{
    font-family: oswald;
    src: url(../fonts/oswald/Oswald-Regular.ttf); 
    font-display: swap;  
}

@font-face{
    font-family: oswald;
    src: url(../fonts/oswald/Oswald-Bold.ttf);
    font-weight: bold;
    font-display: swap;
}

@font-face{
    font-family: oswald;
    src: url(../fonts/oswald/Oswald-Medium.ttf);
    font-weight: 500;
    font-display: swap;
}

@font-face{
    font-family: oswald;
    src: url(../fonts/oswald/Oswald-Light.ttf);
    font-weight: 300;
    font-display: swap;
}

@font-face{
    font-family: oswald;
    src: url(../fonts/oswald/Oswald-ExtraLight.ttf);
    font-weight: 200;
    font-display: swap;
}


@font-face{
    font-family: open-sans;
    src: url(../fonts/open-sans/OpenSans-Regular.ttf);
    font-display: swap;
    
}

@font-face{
    font-family: open-sans;
    src: url(../fonts/open-sans/OpenSans-Bold.ttf);
    font-weight: bold;
    font-display: swap;
}

@font-face{
    font-family: open-sans;
    src: url(../fonts/open-sans/OpenSans-SemiBold.ttf);
    font-weight: 600;
    font-display: swap;
}

@font-face{
    font-family: open-sans;
    src: url(../fonts/open-sans/OpenSans-Light.ttf);
    font-weight: 300;
    font-display: swap;
}

@font-face{
    font-family: open-sans;
    src: url(../fonts/open-sans/OpenSans-ExtraBold.ttf);
    font-weight: 800;
    font-display: swap;
}

*{
    margin:0;
    padding: 0;
}
body{
    font-family: open-sans !important;
}

:root{
    --primary-color: #0a3161;
    --secondary-color: #e70c56;
    --success-color: #00b300;
}
.text-open-sans{
    font-family: open-sans;
}
.text-oswald{
    font-family: oswald;
}
.logo-img{
    height:47px !important;
}

#website-nav {
    /* background-color: var(--primary-color); */
    background-color: #fff;
    
    padding: 0rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 10px solid var(--secondary-color);
}


#website-nav .nav-link{
    color: var(--primary-color);
    text-decoration: none;
    padding: 0.5rem;
    font-size: 1rem;
    /* font-weight: 600 !important; */
    margin-right: 20px;
    font-family: open-sans;
}

/* #website-nav .navbar-toggler{
    border: 0;
    border-radius: 0%;
    background-color: #f2f9ff29;

} */

#website-nav .navbar-toggler{
    border: 0;
    background-color: #ffffff;
    border-radius: 0%;
}

#website-nav .navbar-toggler:focus{
    outline: none;
    box-shadow: none;
}

#website-nav .offcanvas{
    background-color: var(--primary-color);
    color: #fff;
    padding: 0rem;
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
}

.home-section-container{
    padding-top: 100px
}

.container-zk{
    max-width: 90%;
    padding-right: 5%;
    padding-left: 5%;
    margin-right: auto;
    margin-left: auto;
}

.container-zk2{
    max-width: 86%;
    padding-right: 7%;
    padding-left: 7%;
    margin-right: auto;
    margin-left: auto;
}

.border-shadow-in{
    filter: drop-shadow(0 0 0.75rem rgb(225, 223, 223));
}


#id_17{
    display: none;
}

.fs-zk-48{
    font-size: 48px;
}

.fs-zk-40{
    font-size: 40px;
}
.fs-zk-35{
    font-size: 35px;
}

.fs-zk-32{
    font-size: 32px;
}

.fs-zk-30{
    font-size: 30px;
}

.fs-zk-28{
    font-size: 28px;
}

.fs-zk-24{
    font-size: 24px;
}
.fs-zk-20{
    font-size: 20px;
}

.fs-zk-18{
    font-size: 18px;
}

.fs-zk-16{
    font-size: 16px;
}

.fs-zk-14{
    font-size: 14px;
}

.stats-bg{
    background: url("../images/home/stats.webp");
}

/* .about-apply-section {
    background: url("../images/about/applynowbg.png");
    position: relative;
    min-height: 300px;
    width: 100%;
} */

.rounded30{
    border-radius: 30px;
}

.btn-zk-primary{
    background-color: var(--primary-color) !important;
    color: #fff !important;
    cursor: pointer !important;
    border-radius: 4px !important;
    /* height: 3em !important; */
    min-width: 120px !important;

}

.btn-zk-secondary2{
    background-color: var(--secondary-color) !important;
    color: #fff !important;
    cursor: pointer !important;
    border-radius: 0px !important;
    font-family: open-sans !important;
    font-weight: 600 !important;

}

.btn-zk-outline-secondary2{
    background-color: #fff !important;
    color: var(--secondary-color) !important;
    cursor: pointer !important;
    border-radius: 0px !important;
    font-family: open-sans !important;
    font-weight: 600 !important;
    border: 1px solid var(--secondary-color) !important;

}

.about-apply-section  .btn-zk-outline-white{
    background-color: transparent !important;
    color: #fff !important;
    cursor: pointer !important;
    border-radius: 0px !important;
    font-family: open-sans !important;
    font-weight: 600 !important;
    border: 1px solid #fff !important;
}



.btn-zk-secondary{
    background-color: var(--secondary-color) !important;
    color: #fff !important;
    cursor: pointer !important;
    border-radius: 4px !important;
    height: 3em !important;
    min-width: 120px !important;

}

.btn-zk-success{
    background-color: var(--success-color) !important;
    color: #fff !important;
    cursor: pointer !important;
    border-radius: 4px !important;
    height: 3em !important;
    min-width: 120px !important;

}
.btn-zk-primary:hover{
    background-color: var(--primary-color) !important;
    opacity: 0.9 !important;
    border: 1px solid var(--primary-color) !important;
    color: #fff !important;
}

.btn-zk-secondary:hover{
    background-color: var(--secondary-color) !important;
    opacity: 0.9 !important;
    border: 1px solid var(--secondary-color) !important;
    color: #fff !important;
}

.btn-zk-secondary2:hover{
    background-color: var(--secondary-color) !important;
    opacity: 0.9 !important;
    border: 1px solid var(--secondary-color) !important;
    color: #fff !important;
}
#section1-home .margin70{
    margin-top: 70px;
}

.btn-zk-outline-secondary2:hover{
    background-color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    color: #fff;
}

.about-apply-section .btn-zk-outline-white:hover{
    background-color: rgba(255, 255, 255, 0.079);
    border: 1px solid #fff;
    color: #fff;
}

.btn-zk-success:hover{
    background-color: var(--success-color);
    opacity: 0.9;
    border: 1px solid var(--success-color);
    color: #fff;
}

.nav-tabs .nav-link{
    color: #000;
    font-weight: 500;
    border: 0;
    border-bottom: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 20px;
    padding-right: 20px;
}

.nav-tabs .nav-link.active{
    color: var(--secondary-color);
    border-radius: 0%;
    border-bottom: 2px solid var(--secondary-color);
    background-color: #00a65211;
}

.StepButton-d2-0-2-8.active{
    background-color: var(--secondary-color) !important;
}

.StepButton-d2-0-2-8.completed{
    background-color: rgb(224, 224, 224) !important;
    border: 1px solid var(--secondary-color) !important;
}
.StepButton-d6-0-2-18.active{
    background-color: var(--secondary-color) !important;
}

.StepButton-d6-0-2-18.completed{
    background-color: rgb(224, 224, 224) !important;
    border: 1px solid var(--secondary-color) !important;
}

#zoom-img img:hover{
    transform: scale(1.2);
    transition: 0.5s;
    cursor: zoom-in;
}

#zoom-img img{
    transition: 0.5s;
}

.bg-forms{
    background-color: #F3F3FE;
}

.form-control{
    border-radius: 3px;
    border: 1px solid #ccc;
    padding: 7px ;
}

.form-checkout{
    border-radius: 3px;
    box-shadow: 0 4px 4px rgb(87 100 126 / 21%);
}

#footer{
    background: url(../images/footer.webp) no-repeat;
    background-size: cover;
    padding: 100px 0;
}
.order-now-img-wh{
    width: 150px;
    height: 150px;
}

.bg-zk-primary{
    background-color: var(--primary-color);
}

.text-zk-primary{
    color: var(--primary-color);
}

a.text-zk-primary:hover{
    color: var(--primary-color) !important;
    opacity: 0.9;
}

.bg-zk-secondary{
    background-color: var(--secondary-color);
}

.text-zk-secondary{
    color: var(--secondary-color);
}

.howto-title{
    position: absolute;
    left: 10%;
    bottom: 42%;
    width: fit-content;
    font-size: 4vw;
}


.about-applynow-title{
    position: absolute;
    left: 25%;
    bottom: 35%;
    width: fit-content;
    text-transform: uppercase !important;
    font-size: 4vw !important;
}

.page-banner-title{
    color: #fff;
    padding: 10px;
    position: absolute;
    text-transform: uppercase;
    left: 6vw;
    font-size: 4vw;
    font-weight: 900;
    bottom: 17vw;
    width: 80%;
    line-height: 1.1;
}

.page-banner-text{
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 8vw;
    font-size: 1.5vw;
    font-weight: 500;
    bottom: 20vw;
    width: 80%;

}

.page-banner-btns{
    position: absolute;
    left: 8.7vw;
    bottom: 17vw;
    width: 80%;
}
.home-page-bg{
    background: url(../images/home/bg.webp) no-repeat;
    background-size: cover;
    position: relative;
}
.heading-line-left{
    /* line to left */
    position: relative;
    width: 100%;
    height: 1px;
    background: #000;
    margin: 0 auto;
    margin-bottom: 20px;
    content: "";

}

.heading-line-left:before{
    content: "";
    position: absolute;
    height: 3px;
    background-color: var(--primary-color);
    top: 0;
    left: -90px;
    width: 80px;
}

.heading-line-left:after{
    content: "";
    position: absolute;
    height: 3px;
    background-color: var(--primary-color);
    top: 0;
    right: -180px;
    width: 80px;
}
.how-it-work-icon{
    width:100px;
}

.accordion-button:focus{
    border-color: #fff !important;
}
.accordion-button:not(.collapsed) {
    background-color: #fff !important;
}


#faq-home .accordion-item{
    border-bottom: 1px solid var(--secondary-color);
}
.slick-slide {
    /* text-align: center; */
    position: relative;
  }
  .banner-section{
    position: relative;
  }
  .about-apply-section{
    position: relative;
  }
  .how-work-apply-section{
    position: relative;
  }
  .slick-slide:focus {
    outline: none;
  }

  .slick-slide-image {
    max-width: 100%;
    height: auto;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  }
  .slick-slide-label {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0px;
    font-size: 1.5em;
    bottom: 0px;
    width: 100%;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #01054e !important;
  }

  .slick-slide-title {
    color: #fff;
    padding: 10px;
    position: absolute;
    text-transform: uppercase;
    left: 8vw;
    font-size: 3.5vw;
    font-weight: 900;
    top: 14%;
    width: 80%;
    line-height: 1.4;
  }
  .slick-slide-title-desc {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 8vw;
    font-size: 1.5vw;
    font-weight: 500;
    top: 55%;
    width: 80%;
  }
  .slick-slide-btn{
    position: absolute;
    left: 9vw;
    font-size: 1vw;
    font-weight: 500;
    top: 67%;
    width: 80%;
  }
  
  .slick-slide-btn button {
    font-size: 1.1vw !important;
    }

.slider-wrapper {
    width: 100%;

    margin: auto;
  }
  .lh-order-home{
    letter-spacing: 3px;
  }
  #website-nav .align-items-nav{
    align-items: center;
  }

  .home-section3{
    background-color: #e6e6e6a1;   
  }
  .home-section4{
    background: url('../images/home/section4.webp') no-repeat;
    background-size: contain;
  }
  .opacity-60{
    opacity: 0.6;
  }
  .rounded-20{
    border-radius: 20px !important;
  }
  .shadow-zk{
    box-shadow: 3px 2px 0px 2px rgb(64 67 74 / 21%);
  }
  .shadow-zk2{
    box-shadow: 0px 7px 5px 2px rgb(64 67 74 / 21%);
  }

  .about-section1-heading{
    line-height: 1.2; 
    letter-spacing: 3px;
  }

  .home-section1{
    margin-top: -80px;
    border-radius: "30px 30px 0 0";
  }
  .home-section1{
    min-height: 350px;
  }


.pagination {
    margin-top: 45px;
    /* display: flex; */
    /* list-style: none; */
    text-decoration: none;
    /* outline: none; */
    
}

.pagination>.active>a {
    background-color: var(--secondary-color);
    color: #fff;
    text-decoration: none;

}

.pagination>li>a {
    border: 1px solid var(--secondary-color);
    padding: 5px 10px;
    /* outline: none; */
    cursor: pointer;
    text-decoration: none;

}

.pagination>li>a, .pagination>li>span {
    color: var(--secondary-color);
    background-color: rgb(255, 255, 255);
}
.home-banner-mask{
    font-size: 16px !important;
}
.loader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    animation: bg 1s;
}


.loading-wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 142px;
    height: 40px;
    margin: -20px 0 0 -71px;
    background: white;
    filter: contrast(20);
    z-index:10001
    
}
.loading-dot{
    position: absolute;
    width: 16px;
    height: 16px;
    top: 12px;
    left: 5px;
    filter: blur(4px);
    background: #000;
    border-radius: 50%;
    transform: translateX(0);
    animation: dot 2.8s infinite;
}
.loading-dots{
    transform: translateX(0);
    margin-top: 12px;
    margin-left: 31px;
    animation: dots 2.8s infinite;
}
.loading-wrapper span{
    display: block;
    float: left;
    width: 16px;
    height: 16px;
    margin-left: 16px;
    filter: blur(4px);
    background: #000;
    border-radius: 50%;
}
@media screen and (max-width: 988px) {
    .page-banner-title{
        font-size: 4vw;
        bottom: 25vw;
    }
    .page-banner-text{
        font-size: 2.5vw;
        bottom: 22vw;
    }
    .page-banner-btns{
        bottom: 19vw;
    }
    #websive-nav .align-items-center{
        align-items: unset;
    }
    .logo-img{
        height: 35px !important;
    }
    .about-applynow-title{
        left: 25%;
        bottom: 35%;
        width: fit-content;
        text-transform: uppercase !important;
    }
    .container-zk2{
        max-width: 100%;
    }

    .howto-title{
        position: absolute;
        left: 10%;
        bottom: 46%;
        /* width: fit-content;
        font-size: 4vw; */
    }

}

@media screen and (max-width: 788px) {
    
    .page-banner-title{
        font-size: 7vw;
        left: 1vw;
        bottom: 35%;
    }
    /* .page-banner-text{
        font-size: 3vw;
        bottom: 40%;
    } */
  .home-section4{
    background-size: cover;
  }
  .howto-title{
    position: absolute;
    left: 10%;
    bottom: 48%;
}
.heading-line-left:after{
    right: -150px;
}

#website-responsive .fs-zk-48{
    font-size: 30px;
}
#website-responsive .fs-zk-35{
    font-size: 25px;
}
#website-responsive .fs-zk-32{
    font-size: 24px;
}
#website-responsive .fs-zk-24{
    font-size: 20px;
}
.home-section3 .fs-zk-24{
    font-size: 18px !important;
}
#website-responsive .fs-zk-40{
    font-size: 28px;
}

#website-responsive .fs-zk-30{
    font-size: 22px;
}
.logo-img{
    height: 35px !important;
}
.home-section-container{
    padding-top: 40px;
}
.lh-order-home{
    letter-spacing: 1.3px;
  }
  .home-banner-btn{
    font-size: 12px !important;
  }
  .btn-about-howitwork{
    font-size: 15px !important;
  }
  .btn-checkout-btns{
    font-size: 12px !important;
  }

.order-now-btn{
    padding: 7px 25px !important;
}
.order-now-img-wh{
    width: 100px;
    height: 100px;
}
.how-it-work-icon{
    width: 70px;
}

}

@media screen and (max-width: 658px) {
    
    /* .page-banner-title{
        font-size: 5vw;
        bottom: 40%;
    }
    .page-banner-text{
        font-size: 3.5vw;
        bottom: 45%;
    } */
    .slick-slide-title{
        font-size: 6vw;
        top: 17%;
        left: 3vw;
        line-height: 1.2;
        /* top: 35%; */
    }
    .slick-slide-title-desc{
        font-size: 3.2vw;
        top: 58%;
        left: 3vw;
    }
    .slick-slide-btn{
        top: 73%;
        left: 4.3vw;

    }
    .slick-slide-btn button{
        font-size: 1.2vw !important;
    }

    #website-spacing .fs-zk-48{
        font-size: 35px !important;
        line-height: 1.1 !important;
        letter-spacing: normal !important;
    }
    .about-section1-heading{
        line-height:1.2; 
        letter-spacing: 1.2px;
      }

      .howto-title{
        position: absolute;
        left: 10%;
        bottom: 49%;
    }
    .home-section1{
        margin-top: -80px;
        border-radius: "30px 30px 0 0";
        min-height: "250px !important";
      }
      .home-section1{
        min-height: 250px;
      }
      #footer{
        padding: 40px 0 !important  ;
      }
}

@media screen and (max-width: 470px) {
    /* .page-banner-title{
        font-size: 5.5vw;
        bottom: 50%;
    }
    .page-banner-text{
        font-size: 4.2vw;
        bottom: 55%;
        line-height: normal;

    } */
    .howto-title{
        position: absolute;
        left: 10%;
        bottom: 52%;
    }

}

@media screen and (max-width: 400px) {
    /* .page-banner-title{
        font-size: 5.5vw;
        bottom: 45%;

    }
    .page-banner-text{
        font-size: 3.4vw;
        bottom: 40%;
        line-height: normal;
        padding-top: 6px;

    } */
    .howto-title{
        position: absolute;
        left: 10%;
        bottom: 55%;
    }
}